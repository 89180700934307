import React, { useState } from "react";
import { Avatar, Button, FormControlLabel, Grid, Paper, TextField, Typography, Link } from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const paperStyle = {
    padding: 20, height: '72vh', width: 300, margin: "0 auto"
  }
  const avatarStyle = { background: "green" }
  const textFieldStyle = { marginTop: 10 };
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const btnstyle = { margin: "8px 0" }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Retrieve existing user data from local storage
    const existingUserData = JSON.parse(localStorage.getItem("userData")) || [];

    // Get user input from login form
    const { email, password } = formData;

    // Check if there's a matching user in the stored data
    const matchedUser = existingUserData.find(
      (user) => user.email === email && user.password === password
    );

    if (matchedUser) {
      // Successful login, navigate to the desired page
      navigate("/sidebar");
    } else {
      // Display a warning or alert for unsuccessful login
      alert("Invalid email or password");
    }
  };

  return (
    <Grid>
      <Paper style={paperStyle}>
        <Grid align="center">
          <Avatar style={avatarStyle}><LockIcon /></Avatar>
          <h2>Sign In</h2>
        </Grid>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            placeholder="Enter Email"
            fullWidth
            required
            style={textFieldStyle}
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <TextField
            label="Password"
            placeholder="Enter Password"
            type="password"
            fullWidth
            required
            style={textFieldStyle}
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
          <FormControlLabel
            control={<Checkbox {...label} color="success" />}
            label="Remember me"
          />
          <Button type="submit" color="success" fullWidth variant="contained" style={btnstyle}>
            Sign In
          </Button>
        </form>
        <Typography>
          <Link href="#">Forgot Password?</Link>
        </Typography>
        <Typography>
          Do you have an account?
          <Link href="#" onClick={() => handleChange("event", 1)}>
            Sign Up
          </Link>
        </Typography>
      </Paper>
    </Grid>
  );
}

export default Login;
