import { Box, Grid } from '@mui/material'
import React from 'react'
import Card from './Card'
import Piechart from './Piechart'
import Bargraph from './Bargraph'
import Linegraph from './Linegraph'
const Home = () => {
  return (
    <Box>


<Grid container spacing={2}>
          <Grid item xs={12}>  <Card/> </Grid>
          <Grid item xs={12} sm={6}> <Bargraph/> </Grid>
        <Grid item xs={12} sm={6}>  <Piechart /><Linegraph /></Grid>
        </Grid>
    </Box>
  )
}

export default Home