import React, { useState } from "react";
import {
  Avatar,
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
// import Login from "./Login";

const SignUp = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    gender: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    agreeTerms: false,
  });

  const paperStyle = { padding: 20, width: 300, margin: "0 auto" };
  const headerStyle = { margin: 0 };
  const avatarStyle = { backgroundColor: "purple" };
  const textFieldStyle = {
    marginTop: 10,
  };
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Retrieve existing user data from local storage
    let existingUserData = JSON.parse(localStorage.getItem("userData")) || [];

    // Generate a unique key for the user
    const userKey = `user_${existingUserData.length + 1}`;

    // Add new user data to the array with a unique key
    const newUser = { ...formData, key: userKey };
    existingUserData.push(newUser);

    // Save the updated array to local storage
    localStorage.setItem("userData", JSON.stringify(existingUserData));


    setFormData({
      name: "",
      email: "",
      gender: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      agreeTerms: false,
    });

    console.log(existingUserData);
  };

  return (
    <Grid>
      <Paper style={paperStyle}>
        <Grid align="center">
          <Avatar style={avatarStyle}>
            <AddCircleOutlinedIcon />
          </Avatar>
          <h2 style={headerStyle}>Sign Up</h2>
          <Typography variant="caption">
            Please fill this form to create an account
          </Typography>
        </Grid>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Name"
            onChange={handleChange}
            placeholder="Enter Name"
            style={textFieldStyle}
            name="name"
          />
          <TextField
            fullWidth
            label="Email"
            onChange={handleChange}
            placeholder="Enter Email"
            style={textFieldStyle}
            name="email"
          />
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Gender
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
            >
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
          <TextField
            fullWidth
            label="Phone Number"
            onChange={handleChange}
            placeholder="Enter 10-digit Phone Number"
            style={textFieldStyle}
            name="phoneNumber"
          />
          <TextField
            fullWidth
            label="Password"
            onChange={handleChange}
            placeholder="Enter Password"
            type="password"
            style={textFieldStyle}
            name="password"
          />
          <TextField
            fullWidth
            label="Confirm Password"
            onChange={handleChange}
            placeholder="Re-Enter Password"
            type="password"
            style={textFieldStyle}
            name="confirmPassword"
          />
          <FormControlLabel
            control={
              <Checkbox
                {...label}
                color="secondary"
                onChange={handleChange}
                name="agreeTerms"
              />
            }
            label="I agree with Terms & Conditions"
          />
          <Button type="submit" variant="contained" color="success" >
            Sign Up
          </Button>
        </form>
      </Paper>
    </Grid>
  );
};

export default SignUp;
