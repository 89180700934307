import * as React from 'react';
import {Link,  Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import SecurityIcon from '@mui/icons-material/Security';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';
import PhishingOutlinedIcon from '@mui/icons-material/PhishingOutlined';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PieChartIcon from '@mui/icons-material/PieChart';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArticleIcon from '@mui/icons-material/Article';
import VisibilityIcon from '@mui/icons-material/Visibility';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import Combine from './LoginSignin/Combine'
import { useNavigate } from 'react-router-dom';



const drawerWidth = 240;

function SidebarLink({ to, icon, text, notifications }) {
  
  return (
    <Link to={to} style={{ textDecoration: 'none', color: 'inherit' }}>
      <ListItem key={text} disablePadding>
        <ListItemButton>
          <ListItemIcon>
            {/* Wrap the icon with Badge if there are unread notifications */}
            {notifications ? (
              <Badge badgeContent={notifications} color="error">
                {icon}
              </Badge>
            ) : (
              icon
            )}
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItemButton>
      </ListItem>
    </Link>
  );
}
function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    // Perform any logout actions here

    // Redirect to the login page
    navigate("/");
    handleMenuClose();
  };

  const drawer = (
    <div>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2, backgroundColor: '#16383f', color: 'white' }}>
        <Avatar onClick={handleMenuOpen}>M</Avatar>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
          <MenuItem onClick={handleMenuClose}>About</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
        <p>Mayank Mishra</p>
      </Box>
      <Divider />
      <List sx={{ display: 'flex', flexDirection: 'column', padding: 2, backgroundColor: '#16383f', color: 'white' }}>
        <SidebarLink to="./Dashboard.js" icon={<ShowChartIcon sx={{color: 'white'}} />} text="Dashboard" />
        <SidebarLink to="./Application.js" icon={<Badge badgeContent={4} color="error"><ViewInArIcon sx={{color: 'white'}} /></Badge>} text="Application" notifications={4} />
        <SidebarLink to="./Authentication.js" icon={<SecurityIcon sx={{ color: 'white' }} />} text="Authentication" />
        <SidebarLink to="./Pages.js" icon={<FileCopyIcon sx={{color: 'white'}} />} text="Pages" />
      </List>
      <Divider />
      <List sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2, backgroundColor: '#16383f', color: 'white' }}>
        <p>USER INTERFACE</p>
        {[
          { text: 'Components', icon: <BackupTableIcon sx={{color: 'white'}}/> },
          { text: 'Content', icon: <TitleOutlinedIcon sx={{color: 'white'}}/> },
          { text: 'Utilities', icon: <PhishingOutlinedIcon sx={{color: 'white'}}/> },
          { text: 'Forms', icon: <PlaylistAddCheckIcon sx={{color: 'white'}}/> },
          { text: 'Tables', icon: <FormatListBulletedIcon sx={{color: 'white'}}/> },
          { text: 'Charts', icon: <PieChartIcon sx={{color: 'white'}}/> },
          { text: 'Maps', icon: <LocationOnIcon sx={{color: 'white'}}/> },
        ].map((item, index) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2, backgroundColor: '#16383f', color: 'white' }}>
        <p>GETTING STARTED</p>
        {[
          { text: 'Documentation', icon: <ArticleIcon sx={{color: 'white'}}/> },
          { text: 'Changelog', icon: <VisibilityIcon sx={{color: 'white'}}/> },
        ].map((item, index) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    // <Router>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
            backgroundColor: 'black',
            color: 'white',
          }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
        >     
         <Outlet/>
        </Box>
      </Box>
  );
}

export default ResponsiveDrawer;