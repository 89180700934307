import * as React from 'react';
// import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export default function Dashboard() {
  const [isGraphOpen, setGraphOpen] = React.useState(true);

  const toggleGraph = () => {
    setGraphOpen(!isGraphOpen);
  };

  const rows = [
    createData('Canada', 5555, 210, 24.6, 0.26),
    createData('India', 24152, 135, 0.58, 0.43),
    createData('UK', 15640, 324, 5.15, 24.7),
    createData('USA', 3054, 357, 6.25, 4.34),
  ];

  return (
   
      <Accordion
        expanded={isGraphOpen}
        onChange={() => {}}
        sx={{
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)'

        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={toggleGraph}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography variant="h6">COUNTRY STATS</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={[
                { name: 'Mon', value: 4, value2: 1 },
                { name: 'Tues', value: 3, value2: 6 },
                { name: 'Wed', value: 5, value2: 3 },
                { name: 'Thurs', value: 5, value2: 3 },
                { name: 'Fri', value: 5, value2: 3 },
                { name: 'Sat', value: 5, value2: 3 },
                { name: 'Sun', value: 5, value2: 3 },
              ]}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="orange" />
              <Bar dataKey="value2" fill="purple" />
            </BarChart>
          </ResponsiveContainer>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Country</TableCell>
                  <TableCell align="right">Sessions</TableCell>
                  <TableCell align="right">Goals</TableCell>
                  <TableCell align="right">Goals Rate</TableCell>
                  <TableCell align="right">Bounce Rate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.calories}</TableCell>
                    <TableCell align="right">{row.fat}</TableCell>
                    <TableCell align="right">{row.carbs}</TableCell>
                    <TableCell align="right">{row.protein}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
   
  );
}
