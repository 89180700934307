import React from 'react';
import './App.css';
import Sidebar from './Sidebar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Combine from './LoginSignin/Combine';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Combine />} />
        <Route path="/Sidebar/*" element={<Sidebar />}>
          <Route index element={<Home />} />
          
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
