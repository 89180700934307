import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default function Dashboard() {
  return (
   
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
          '& > :not(style)': {
            m: 1,
            width: 400,
            height: 150,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 2,
            borderRadius: 3,
          },
        }}
      >
        <Paper elevation={3} sx={{ background: "linear-gradient(51deg, rgba(227,187,21,1) 2%, rgba(235, 115,29,1) 49%, rgba(215,76,12,1) 75%)" }}>
          <Typography variant="body2" sx={{ color: 'white', textAlign: 'left', alignSelf: 'flex-start' }}>Visitors</Typography>
          <Typography variant="h4" sx={{ color: 'white', textAlign: 'left', alignSelf: 'flex-start', margin: '10px 0' }}>13.7K</Typography>
          <Typography variant="body2" sx={{ color: 'white', textAlign: 'left', alignSelf: 'flex-start' }}>172,458 Target users</Typography>
        </Paper>

        <Paper elevation={3} sx={{ background: "linear-gradient(51deg, rgba(4,73,12,1) 7%, rgba(8, 175,27,1) 55%, rgba(147,240,4,1) 95%)" }}>
          <Typography variant="body2" sx={{ color: 'white', textAlign: 'left', alignSelf: 'flex-start' }}>Bounce Rate</Typography>
          <Typography variant="h4" sx={{ color: 'white', textAlign: 'left', alignSelf: 'flex-start', margin: '10px 0' }}>41.6%</Typography>
          <Typography variant="body2" sx={{ color: 'white', textAlign: 'left', alignSelf: 'flex-start' }}>472,458 Targeted Target Users</Typography>
        </Paper>

        <Paper elevation={3} sx={{ background: "linear-gradient(51deg, rgba(227,42,86,1) 16%, rgba(184,29,235,1) 45%, rgba(159,6,246,1) 95%)" }}>
          <Typography variant="body2" sx={{ color: 'white', textAlign: 'left', alignSelf: 'flex-start' }}>Users</Typography>
          <Typography variant="h4" sx={{ color: 'white', textAlign: 'left', alignSelf: 'flex-start', margin: '10px 0' }}>19M</Typography>
          <Typography variant="body2" sx={{ color: 'white', textAlign: 'left', alignSelf: 'flex-start' }}>172,458 Target users</Typography>
        </Paper>
        
      </Box>
    
  );
}
